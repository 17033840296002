export default [
  							
  // { name: '首頁 ', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: 0 },
  { name: '捷運富人', imgSrc: '', subTitle: '', section: 'section2', svgSrc: '', offset: 0 },
  { name: '明星學區', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', offset: 0},
  { name: '生活機能', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '', offset: 0 },
  { name: '崗石建築', imgSrc: '', subTitle: '', section: 'section5', svgSrc: '', offset: 0 },
  { name: '質感公設', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', offset: 0 },
  { name: '頂尖團隊', imgSrc: '', subTitle: '', section: 'section7', svgSrc: '', offset: 0 },
  { name: '豪宅精工', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '', offset: 0 },
  { name: '樣品屋', imgSrc: '', subTitle: '', section: 'section9', svgSrc: '', offset: 0 },
  { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', offset: 0 }
  // { name: '預約賞屋', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', offset: 0 },
  // { name: '立即預約', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: 0, offset: 0 },
  // { name: '地圖導航', imgSrc: '', subTitle: '', section: 'google-map', svgSrc: '', mobileOffset: 0, offset: -150 },
]
