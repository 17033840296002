export default {
  address: "接待中心:台北市北投區中和街239號",
  googleSrc: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.887330477424!2d121.49895931598127!3d25.139499740206013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442ae4f0af68525%3A0x8262339212753f2b!2zMTEy5Y-w5YyX5biC5YyX5oqV5Y2A5Lit5ZKM6KGXMjM56Jmf!5e0!3m2!1szh-TW!2stw!4v1631441848806!5m2!1szh-TW!2stw",
  googleLink: "https://goo.gl/maps/c4K9esd9AJckvLBH8",
  phone: "02-28971888",
  fbLink: "https://www.facebook.com/%E9%99%BD%E6%98%8E%E8%BF%B4%E9%84%89-105686925186832",
  fbMessage: "https://m.me/105686925186832/",
  caseName: "陽明迴鄉",
  indigatorLength: 10,
  houseInfos: [
      ["投資興建", "寶程國際"],
      ["營造工程", "奕捷營造股份有限公司"],
      ["結構外觀", "蔡家欣 建築師"],
      ["結構安全", "品創工程顧問"],
      ["不動產信託", "第一商業銀行"],
      ["規劃坪數", "19、26、37坪"],
      ["空間規劃", "森恒空間設計 林世昌 總監"],
      ["建築執照", "109建字第0119號"],
      ["廣告銷售", "得邦廣告"],
  ],

  gtmCode: ["PJRWQ5W"], // 可放置多個
  recaptcha_site_key_v2: "6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz",
  recaptcha_site_key: "6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s", // recaptcha v3
  recaptcha_user_token: "6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa",
  order: {
      title: "立即預約",
      subTitle: "若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！"
  }
}